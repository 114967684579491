
.wrapper
	text-transform: uppercase
	color: #333333
	padding-top: 8px
	font-size: 19px
	font-weight: 700
	display: flex
	gap: 0.5em
	align-items: center

.previous,
.next
	display: flex
	padding: 0

.previous
	transform: scaleX(-1)

.month
	min-width: 6.25rem
	text-align: center

.today
	margin-left: 2em
	display: flex
	align-items: center
	height: 0

	@media not all and (min-width: 31.25rem)
		display: none
