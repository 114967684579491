@use "../_common"

.wrapper
  padding-top: 100%
  position: relative

.content
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  clip-path: common.$squircle
  overflow: hidden
