=bodyFullHeight
	#__next
		display: contents

	html,
	body
		outline: none
		padding: 0
		margin: 0

	html
		height: 100%

	body
		overflow-y: scroll
		min-height: 100vh
		min-height: 100svh
		display: grid
		grid-template-columns: 1fr auto // Auto for next-route-announcer
