@use 'sass:math'
@use 'common'

$breakMobile: 50rem

.wrapper
	position: relative // for EditBox overlay
	background-color: var(--global-colors-light)
	padding: 1.25rem
	min-height: 100%
	display: flex
	flex-direction: column
	gap: 2rem
	@media ( min-width: $breakMobile )
		padding: 1.875rem

.heading
	background-color: var(--global-colors-light)
	display: flex
	justify-content: space-between
	align-items: center
	position: sticky
	top: 0
	z-index: 1
	&::before
		content: ""
		display: block
	&:not(:first-child)
		flex-grow: 1
	@media  ( min-width: $breakMobile )
		position: relative
		align-items: flex-start

.title
	margin-block: 0
	text-transform: uppercase
	text-align: center
	line-height: math.div(39,30)
	font-size: 1.5rem
	@media ( min-width: $breakMobile )
		font-size: 1.875rem

.closeButton
	width: 100%
	max-width: 1.5rem
	@media ( min-width: $breakMobile )
		max-width: 1.875rem
