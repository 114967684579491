@use "../_common"

$spacing: .5em

.list
	width: 100%
	padding: 0.5 * $spacing

.item
	padding: 0.5 * $spacing

	&:not(:last-child)
		margin-bottom: 0.5em

