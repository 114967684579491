.wrapper
	position: relative
	aspect-ratio: 1
	border-radius: 50%
	overflow: hidden

	&::before
		content: ''
		position: absolute
		border-radius: 50%
		inset: 0.0625rem // Prevents ugly image blend edge
		background-color: currentColor
		opacity: 0.15

.image
	object-fit: cover
