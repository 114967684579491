@use 'common'
@import '@goodlok/ui/src/styles/globals.sass'

@font-face
	font-family: common.$fontNeueVektorName
	font-weight: 400
	src: url("/fonts/58cec528-68e6-4864-b1a7-7dbee7d452fd.woff2") format("woff2"),url("/fonts/553a269b-03db-40d1-ab17-83eaf6362609.woff") format("woff")

@font-face
	font-family: common.$fontNeueVektorName
	font-weight: 600
	src: url("/fonts/83b15a14-c534-4c13-9500-e6b8af6cb68f.woff2") format("woff2"),url("/fonts/56a24937-8f74-4669-a30d-034016bab543.woff") format("woff")

@include bodyFullHeight

html,
body
	background: #EDEAE5
	color: var(--global-colors-dark)
	font-family: common.$fontNeueVektor

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box

ul
	list-style-type: none
	padding-left: 0

input
	font-family: inherit

button
	border: 0
	cursor: pointer
	background: transparent
	font-family: inherit
	font-size: 100%
	line-height: 1.15
	margin: 0
	padding: 0
	color: inherit

label
	cursor: pointer

div,
input,
textarea,
button,
select,
a
	-webkit-tap-highlight-color: rgba(0,0,0,0)

legend
	padding: 0
	display: table

fieldset
	border: 0
	padding: 0.01em 0 0 0 // https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
	margin: 0
	min-width: 0

body:not(:-moz-handler-blocked) fieldset
	display: table-cell

// +common.debug()
