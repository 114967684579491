@use "../_common"

$squircleInitialOpacity: 0
$squircleActiveOpacity: 1

$color: common.$goodlokDark
$squircleColor: common.$goodlokLight

$iconInitialOpacity: 0.3
$iconActiveOpacity: 1

$-break: 44rem

.wrapper
	color: $color
	position: relative
	font-weight: 600
	text-align: center

	&.isActive
		color: var(--AppSwitcherIcon-color, #{$color})

.toolTip
	position: absolute
	top: 50%
	left: 100%
	transform: translate(.625rem, -50%)
	opacity: 0
	visibility: hidden
	transition-property: opacity, visibility, transform
	transition-duration: 0.2s
	transition-timing-function: ease-out
	padding: .25rem .5rem
	margin-left: .5rem
	background-color: var(--global-colors-dark)
	color: var(--global-colors-light)
	white-space: nowrap
	border-radius: .25rem
	font-weight: 400
	pointer-events: none

	@media not all and (min-width: $-break)
		display: none

	&::before
		content: ""
		width: .5rem
		height: .5rem
		background-color: var(--global-colors-dark)
		position: absolute
		top: 50%
		left: 0
		transform: translate(-50%, -50%) rotate(45deg)

	.button:hover &,
	.button:focus &
		opacity: 1
		visibility: visible
		transform: translateY(-50%)

.squircle
	background: rgba($squircleColor, $squircleInitialOpacity)
	transition: background ease-in-out .2s
	width: 100%

	.isActive &,
	.wrapper:not(.noHoverEffect) &:hover,
	.wrapper:not(.noHoverEffect) &:active,
	.wrapper:not(.noHoverEffect) &:focus
		background: rgba($squircleColor, $squircleActiveOpacity)

	.icon
		font-size: 2em

	.inactiveIcon,
	.activeIcon,
	.singleIcon
		width: 100%
		height: 100%
		display: block
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		display: flex
		align-items: center
		justify-content: center
		opacity: $iconInitialOpacity

	.activeIcon
		opacity: 0

		.isActive &,
		.button:active
			opacity: $iconActiveOpacity

	.inactiveIcon
		.isActive &,
		.button:active
			opacity: 0

.button
	padding: 0
	width: 100%
	display: inline
