@use '../_common'

$-divider-height: 1px
$-divider-color: #000000
$-background-color: common.$goodlokLight
$-break: 44rem

.wrapper
	display: contents

.primary
	$vertical-spacing: 1.5rem

	padding: $vertical-spacing var(--global-page-horizontal-spacing) 0
	display: flex
	align-items: center

	.is_simple &
		border-bottom: $-divider-height solid $-divider-color
		padding-bottom: $vertical-spacing

	@media (min-width: $-break)
		display: none

.appName
	display: contents

.name
	margin: 0
	font-size: 1.5em

.icon
	flex-shrink: 0
	font-size: 2em
	line-height: 1
	display: grid
	align-items: center
	text-align: center
	padding-right: 0.25em

.secondary
	position: sticky // @TODO: don't stick on low height viewport
	top: 0
	z-index: 9
	border-bottom: $-divider-height solid $-divider-color
	background-color: $-background-color
	padding: 1rem var(--global-page-horizontal-spacing)

	&:empty
		display: none
