.wrapper
	display: flex
	// flex-wrap: wrap
	gap: 0.5em
	align-items: center
	padding-bottom: 1em
	background: rgba(#000, .05)
	padding: .5em 1em
	border-radius: .5em

.note
	p
		margin: 0

.actions
	margin-left: auto

