.button
	position: relative
	aspect-ratio: 1
	width: 100%
	padding: 0
	&::after,
	&::before
		content: ''
		background-color: currentColor
		position: absolute
		width: 100%
		height: .125rem
		left: 50%
		top: 50%
	&::after
		transform: translate(-50%, -50%) rotate(45deg)
	&::before
		transform: translate(-50%, -50%) rotate(-45deg)
