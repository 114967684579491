$goodlokLight: #EDEAE5
$goodlokDark: #28282a

$-break: 44rem
$sideBar-width: 4em

.wrapper
	--AppFrame-switcher-placeholder: 0
	display: grid

.actions
	padding: .5em

.action
	opacity: .2
	background: rgba(#000000, .5)
	transition: opacity ease-in-out 0.3s

	.isActive &,
	&:hover,
	&:active,
	&:focus
		opacity: 1

.sideBar
	display: flex
	transform: translateX(-100%)
	transition: transform ease-in-out 0.3s
	flex-direction: row
	align-items: stretch
	justify-content: stretch
	flex-grow: 0
	background: #ffffff
	color: $goodlokDark
	position: relative
	z-index: 10
	width: $sideBar-width
	min-height: 100vmin

	=fixed
		position: fixed
		top: 0
		bottom: 0
		z-index: 20

	.wrapper.is_onDemandOnly &
		+fixed

	@media not all and (min-width: $-break)
		+fixed

	.supportNotch &
		padding-left: env(safe-area-inset-left)

	$navigation-size: .375rem
	$navigation-y-offset: .25rem
	&::before,
	&::after
		content: ""
		position: sticky
		left: calc(2em - #{$navigation-size})
		width: 0
		height: 0
		z-index: 1
		box-shadow: 0 0 .625rem .625rem var(--global-colors-light)
		background-color: var(--global-colors-light)
		border-style: solid
		border-width: 0 $navigation-size $navigation-size $navigation-size
		border-color: transparent transparent var(--global-colors-dark) transparent
		pointer-events: none
		opacity: 0
		transition: 0.3s opacity

	&::before
		top: $navigation-y-offset
	&::after
		top: calc(100vh - 2 * #{$navigation-size} - #{$navigation-y-offset})
		transform: scaleY(-1) translateY(-100%)

	&.is_previousNavigationVisible::before
		opacity: 1
	&.is_nextNavigationVisible::after
		opacity: 1

// prevents floating content form being hidden by overflow
.sideBarOverflowGuard
	position: absolute
	top: 0
	left: 0
	bottom: 0
	width: 25rem
	pointer-events: none

.sideBarStickyWrapper
	position: sticky
	top: 0
	height: 100vh
	height: 100dvh
	overflow-y: auto
	-webkit-overflow-scrolling: touch
	scrollbar-width: none
	-ms-overflow-style: none

	&::-webkit-scrollbar
		display: none

	.is_mouseOverSideBar &
		pointer-events: all

.sideBarContent
	width: $sideBar-width
	height: 100%

.main
	width: 100%
	display: flex
	flex-direction: column
	align-items: stretch
	justify-content: stretch
	position: relative

	&_in
		display: contents

.switcher,
.sideMenu
	width: $sideBar-width
	min-height: 100%

.switcher
	display: flex
	flex-direction: column
	align-items: stretch
	justify-content: stretch
	border-right: solid 1px $goodlokLight
	pointer-events: all

.lists
	display: flex
	flex-direction: column
	align-items: stretch
	justify-content: space-between
	flex-grow: 1
	height: 100%

.sideMenu
	border-right: solid 1px $goodlokLight
	width: 18em

=isOpenState
	--AppFrame-switcher-placeholder: auto

	.sideBar
		transform: translateX(0)

=overlay
	+isOpenState
	--AppFrame-switcher-placeholder: auto

	.main
		&::after
			content: ""
			position: fixed
			top: 0
			left: 0
			bottom: 0
			right: 0
			z-index: 10
			background-color: rgba(#000000, .2)

@media not all and (min-width: $-break)
	.wrapper.is_open
		+overlay

@media (min-width: $-break)
	.wrapper.is_onDemandOnly.is_open
		+overlay

	.wrapper:not(.is_onDemandOnly)
		+isOpenState
		grid-template-columns: var(--AppFrame-switcher-placeholder) 1fr

.userPhoto
	width: 2.5rem

.logo
	display: flex
	justify-content: center
	height: 2.1875rem
	aspect-ratio: 1
